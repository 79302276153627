.crud-grid .MuiDataGrid-cell:has(.Mui-error) {
  background-color: darkred !important;
}

.crud-grid .row-deleted .MuiDataGrid-cellContent {
  color: rgba(255, 255, 255, 0.25);
}

.crud-grid .row-deleted .MuiDataGrid-cellContent[title="deleted"] {
  color: #d34c26;
}

.crud-grid .row-deleted button[aria-label="Edit"],
.crud-grid .row-deleted button[aria-label="Delete"] {
  display: none;
}

.crud-grid .row-error {
  color: #d34c26;
}
