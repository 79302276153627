.scroll-y {
  overflow-y: auto;
}

.chart-title {
  position: absolute;
  z-index: 1;
}

.no-data {
  align-items: center;
  justify-content: center;
}

.custom-tooltip {
  text-align: left;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.85);
}

.custom-tooltip .label {
  font-size: 0.75em;
  display: block;
}

#report {
  position: fixed;
  width: 2480px;
  height: 3508px;
  margin: 0;
  padding: 0;
  z-index: -1000;
  display: hidden;
  pointer-events: none;
  opacity: 0;
}

.dashboard-container {
  height: auto;
  box-sizing: border-box;
  margin: 0;
  background-color: black;
  padding: 5px;
  font-family: "Roboto";
}

.data-card {
  padding: 5px;
  min-width: 0;
}

.data-card,
.data-card-value,
.data-card-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-card-value {
  width: 100%;
  border-radius: 5px;
  border: 2px solid #34B3F1;
  font-size: 2em;
  font-weight: bold;
  overflow: hidden;
}

.data-card-label {
  width: 100%;
}

.shadow-divider {
  border-bottom: 1px solid #ffffff77;
}