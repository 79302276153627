.navbar,
.sidebar {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -ms-tap-highlight-color: transparent;
}

.navbar .active {
  color: #82c3ec;
}

.sidebar {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-tree {
  padding: 5px;
  text-align: left;
  overflow-y: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
