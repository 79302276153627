* {
  box-sizing: border-box;
  /* -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -ms-tap-highlight-color: transparent;
  cursor: none; */
}

html,
body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: #000000;
  /*
  #313c47
  #5e7085
  #12b8ff
  #ebffff
  #000000
  #61dafb
  #434343
  */
  color: #ebffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
  padding: 0;
}