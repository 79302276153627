.App {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  font-family: "Roboto";
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.App > div {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.capitalise {
  text-transform: capitalize;
}

.capitalise-first:first-letter {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  font-size: calc(7px + 1.5vmin);
  margin: 0;
  padding: 0;
}

.flex-cl {
  align-items: center;
  justify-content: flex-start;
}

.flex-cc {
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.flex-8 {
  flex: 8;
}
.flex-9 {
  flex: 9;
}
.flex-10 {
  flex: 10;
}

pre {
  padding: 15px;
  border-radius: 10px;
  background-color: black;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Scrollbar styles */

/* Hide scrollbar for Chrome, Safari and Opera */

/* *::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE and Edge */

/* * {
  -ms-overflow-style: none;
} */

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #ffffff5f;
}
